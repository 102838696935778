import React from 'react';

function App() {
  const onClick = () => {};

  return (
    <div className="bg-slate-400 h-screen flex justify-center items-center">
      <div
        onClick={onClick}
        className="p-10 border-solid border-gray-900 border-2 font-serif font-semibold text-3xl"
      >
        Aaron Lee
      </div>
    </div>
  );
}

export default App;
